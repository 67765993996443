:root {
  --primary-color: #93329e; /* Define your primary color */
  --secondary-color: #440a67; /* Define your secondary color */
  --side-menu-hover-color: #9681eb; /* Define your secondary color */
  --text-color: #333; /* Define your text color */
}
body {
  margin: 0;
  color: hsla(0, 0%, 0%, 0.87);
  font-family: Poppins !important;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
  letter-spacing: 0.00938em;
  background-color: #ffffff;
  padding-right: 0px !important;
}

* {
  font-family: Poppins !important;
}

a {
  cursor: pointer !important;
}

.appbar-design {
  background: white !important;
  color: var(--secondary-color) !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  top: 0.75rem !important;
}

.appbar-design.static {
  position: static !important;
  background-color: transparent !important;
}

@media (min-width: 768px) {
  .appbar-design.static {
    align-content: flex-end !important;
    flex-wrap: wrap !important;
  }
}

.appbar-design.sticky {
  position: sticky !important;
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: inset 0rem 0rem 0.0625rem 0.0625rem rgb(255 255 255 / 90%),
    0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
  -webkit-backdrop-filter: blur(3px);
}

.text--chateau {
  color: #acb6be;
}

.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
  row-gap: 30px;
}

.avatarColor {
  width: 3rem;
  height: 3rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  margin: 8px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .topnav {
    padding: 5px !important;
  }
}

.topnav {
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
  padding: 24px;
  position: relative;
  width: inherit;
  -webkit-transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* .color--white {
  color: #000000;
  font-size: 13px !important;
}

.MuiCollapse-root .color--white {
  padding-left: 30px !important;
} */

.left-menu {
  margin-top: 30px;
}
.left-menu span {
  /* color: var(--secondary-color); */
  font-size: 15px;
}

.left-menu svg {
  width: 22px !important;
  height: 22px !important;
  color: var(--secondary-color) !important;
}

.left-menu a {
  color: black !important;
}

.sidemenu--item {
  color: rgb(0, 0, 0) !important;
}

.menu_id {
  gap: 20px;
  padding: 7px 18px !important;
  margin: 0px 0px 10px !important;
  /* background-color: #dfccfb !important; */
}

.menu_id.active {
  gap: 20px;
  padding: 7px 18px !important;
  margin: 0px 0px 10px !important;
  background-color: #4d0463 !important;
  color: white !important;
}

.left-menu .menu_id.active svg {
  color: rgb(255, 255, 255) !important;
}

.sidemenu--item > div {
  gap: 20px;
  margin: 5px 0px !important;
}
/* .sidemenu--item > div svg {
  color: red;
} */

.menu_title {
  gap: 20px;
  padding: 10px 18px !important;
  margin: 0px 0px 10px !important;
  font-weight: 600 !important;
  color: #0c54a0;
  font-size: 14px !important  ;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 0px !important;
}

.sidemenu--item > div {
  gap: 20px;
  margin: 5px 0px !important;
}

.sidenav > ul {
  padding: 10px;
}

.logout-btn {
  color: red !important;
  gap: 20px !important;
  margin: 5px 0px !important;
  background: #deeeff !important;
  border-radius: 0.375rem !important;
  font-weight: bold !important;
}

.logout-btn  > div svg {
  color: red;
}


a.sidemenu--item.active div {
  /* background: var(--secondary-color); */
  background: #e6c7ff;
  border-radius: 0.375rem;
}

a.sidemenu--item.active div span {
  color: #3a0752 !important;
  font-weight: bold;
}

a.sidemenu--item.active div svg {
  color: #610964 !important;
}

.sidenav.open {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  z-index: 999999999999999;
  /* margin: 2rem 0 5rem 0 !important; */
}

.sidenav {
  box-shadow: 0px 0px 28px 2px rgb(222, 192, 240);
  border: none;
  /* background: linear-gradient(195deg, #42424a, #191919); */
  -webkit-transform: translateX(-20rem);
  -moz-transform: translateX(-20rem);
  -ms-transform: translateX(-20rem);
  transform: translateX(-20rem);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.6, 1.4) 0ms;
  position: fixed;
  height: calc(100vh - 0rem);
  /* background: linear-gradient(195deg, #42424a, #191919); */
  width: 300px;
  /* margin: 1rem 0 1rem 0; */
  border-radius: 0.75rem;
  background: #eceafa80;
  backdrop-filter: blur(25px);
  border: none;
  border-radius: 26px;
  z-index: 999999999999999;

}

.sidemenu--item:hover {
  /* background-color: #313866; */
  background-color: var(--side-menu-hover-color);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgb(0 0 0 / 10%),
    0rem 0.25rem 0.375rem -0.125rem rgb(0 0 0 / 5%) !important;
  padding: 0.5rem !important;
  border-radius: 0.375rem !important;
}

.profile-setting ul {
  width: 350px !important;
}

.profile-setting ul .profile-name {
  display: grid;
  justify-items: center;
}

.ticket-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 25px;
  margin-top: 25px;
}

.ticket-details {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  color: #00000096;
}

.card-heading2 .ticket-details {
  margin-top: -20px !important;
}

.hrLine {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.07rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.5;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgb(0 59 128),
    rgba(52, 71, 103, 0)
  ) !important;
}

.content-details {
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px,
    rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}

/* .sidenav .btn {
  display: block;
} */

/* icon */

.MuiSvgIcon-colorDisabled {
  color: red !important;
}

.app-icon--micro {
  width: 4px;
  height: 4px;
  fill: #6f7c87;
  margin: 1px 10px;
}

.dashboard-1-card {
  padding: 25px 20px;
  height: 260px;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%),
    0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%) !important;
}

.text--dark {
  color: #183247;
}

.text--xsmall,
.ticket-details__item .created-time {
  font-size: 14px !important;
}

.text--medium {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.text--gray {
  color: #6f7c87;
}

.sidenav {
  overflow: auto;
  scrollbar-width: thin;
}

.sidenav::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.sidenav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#ffffff50),
    to(#ffffff65),
    color-stop(0.6, #ffffff)
  );
}

@media (min-width: 1200px) {
  .topnav {
    margin-left: 17rem;
  }

  .sidenav {
    /* box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%); */
    border-right: 1px dashed rgba(145, 158, 171, 0.24) !important;
    margin-bottom: inherit;
    left: 0;
    width: 280px;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .menu-bar .btn,
  .sidenav .btn {
    display: none;
  }
}

.danger-btn {
  color: red !important;
}

li.MuiTimelineItem-root.MuiTimelineItem-positionRight.timeline.css-ny8qih-MuiTimelineItem-root {
  display: grid;
  grid-template-columns: 130px 13px auto;
}

.type-title {
  /* font-size: 1.25rem !important; */
  color: #344767 !important;
  font-weight: 600 !important;
}

.type-subtitle {
  font-size: 0.85rem !important;
  color: #555555 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  backdrop-filter: blur(3px);
  background: rgb(255 255 255 / 75%);
}

.active-status {
  width: fit-content;
  padding: 3px 15px;
  background: rgb(0 128 0 / 30%);
  color: green;
  border-radius: 5px;
  font-weight: 600 !important;
  margin: left !important;
}

.inactive-status {
  width: fit-content;
  padding: 3px 15px;
  background: rgba(255, 0, 0, 0.219);
  color: red;
  border-radius: 5px;
  font-weight: 600 !important;
  margin: left !important;
}

.hold-status {
  width: fit-content;
  padding: 3px 15px;
  background: rgba(255, 187, 0, 0.3);
  color: rgb(255, 123, 0);
  border-radius: 5px;
  font-weight: 600 !important;
  margin: left !important;
}

.completed-status {
  width: fit-content;
  padding: 3px 15px;
  background: rgba(8, 121, 8, 0.11);
  color: rgb(31, 141, 31);
  border-radius: 5px;
  font-weight: 600 !important;
  margin: left !important;
}

.company-name {
  margin-left: 20px !important;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  color: white !important;
}

.page-title {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  color: #000000;
  padding-bottom: 40px !important;
}

.background--white {
  background: white;
  border-radius: 5px;
  padding: 1% !important;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btnFilter {
  background: #3f1d62 !important;
}

.btn-no-design {
  background-color: transparent !important;
  color: #3f1d62 !important;
  height: 55px;
  border: none !important;
}

.btn-no-design.active {
  background: #a575d6 !important;
  border-radius: 100px !important;
  color: white !important;
}

.todo-list {
  list-style: none;
  padding: 0;
}

.todo-list li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.todo-title {
  color: #3f1d62;

  font-weight: 700;
}

.set-remainder {
  font-size: 0.85rem;
}

.white-bg {
  background: white;
  padding: 30px 15px;
  border-radius: 5px;
  height: auto;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.spinner-white-bg {
  /* background: white; */
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  margin-bottom: 0px !important;
}

.btn-section {
  margin: auto;
  display: flex;
  width: 30%;
}

.card-design {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  background: white;
  border-radius: 5px;
  box-shadow: rgb(63 29 98 / 5%) 0px 1px 10px 5px;
  border-bottom: 2px solid #3f1d62 !important;
  border: 1px solid #ae7cd663;
  margin-bottom: 10px;
  transition: 0.5s;
  margin-top: 10px;
}

.card-design:hover {
  transform: scale(1.03);
}

.text-strike {
  text-decoration: line-through;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.Mui-selected.MuiPaginationItem-page.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: rgb(146 74 201) !important;
  color: white !important;
}

/* SPINNER LOADER START */

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #ae7cd6 #0000;
  animation: spinner-e04l1k 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #924ac9 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 8.9px;
}

@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}

/* SPINNER LOADER END */

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.MuiInputBase-adornedStart.css-111avd1-MuiInputBase-root-MuiOutlinedInput-root {
  flex-wrap: wrap;
  gap: 10px 20px;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.css-iqd1hh-MuiButtonBase-root-MuiChip-root {
  display: grid;
  grid-template-columns: auto 24px;
  justify-content: space-between;
}

/* .btn-primary {
  color: white !important;
  border: 1px solid #914ac8 !important;
  border-radius: 5px !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background: #914ac8 !important;
  padding: 5px 30px !important;
} */

.box {
  display: grid;
  grid-template-columns: 25% 60% auto;
  gap: 30px;
  margin: 0px 0px 30px;
  padding: 20px;
  box-shadow: rgb(174 124 214 / 30%) 0px 7px 7px 0px;
}

.box1 {
  display: grid;
  grid-template-columns: 3% auto;
}

.top-padding-0 {
  padding-top: 0px !important;
}

th {
  background-color: #0c54a0 !important;
  color: #fff !important;
  border: 1px solid;
}

table tr td,
table tr th {
  padding: 5px 10px;
  border: 1px solid #7d87ad;
}

.margin-auto {
  margin: auto !important;
  text-align: center;
}

.inner-popup {
  border: 0 !important;
  border-radius: 10px;
}

.modal-popup div:first-child {
  background: rgb(102 51 153 / 30%) !important;
  backdrop-filter: blur(2px) !important;
}

.inner-popup svg {
  height: 2em !important;
  width: 2em !important;
}

h2#modal-modal-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  color: #3f1d62;
  margin-bottom: 20px;
}

.inner-popup span label:nth-child(3),
.inner-popup span label:nth-child(7) {
  visibility: hidden;
}

.dashboard-card {
  cursor: pointer;
}

.dashboard-todo {
  padding: 10px;
  border-bottom: 1px solid #924ac9;
  transition: ease 0.2s;
  border-radius: 5px;
  gap: 20px;
}

.dashboard-todo:hover {
  background: #924ac9;
  color: white;
  border-radius: 5px;
}

.dashboard-a-tag a {
  color: #924ac9;
}

.css-dqr9h-MuiRating-label span {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: #924ac9;
}

.table-responsive {
  overflow-x: auto;
}

.text-center {
  text-align: center;
}

.btn-secondary {
  border: 1px solid;
  color: #914ac8;
  border-radius: 5px;
}

.action-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.copyright {
  text-align: right;
  padding: 3px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: white;
}

.banner-background {
  background: linear-gradient(
        195deg,
        rgba(73, 163, 241, 0.6),
        rgba(26, 115, 232, 0.6)
      )
      50% center / cover,
    url("https://demos.creative-tim.com/material-dashboard-pro-react/static/media/bg-profile.af1219a742e09fc7b612.jpeg");
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  min-height: 18.75rem;
  opacity: 1;
  color: rgb(52, 71, 103);
  box-shadow: none;
  border-radius: 0.75rem;
  background-position: 50% center;
  overflow: hidden;
}

.details-section {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column !important;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border-radius: 0.75rem;
  overflow: visible;
  position: relative;
  margin-top: 0px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
  padding: 16px !important;
}

.title-text {
  margin: 0px !important;
  font-size: 1.25rem !important;
  line-height: 1.375;
  letter-spacing: 0em !important;
  text-transform: none !important;
  vertical-align: unset !important;
  text-decoration: none !important;
  color: rgb(52, 71, 103) !important;
  font-weight: 600 !important;
}

.sub-title-text {
  margin: 0px !important;
  font-size: 1.3rem !important;
  letter-spacing: 0em !important;
  padding-bottom: 15px;
  text-transform: none !important;
  vertical-align: unset !important;
  text-decoration: none !important;
  color: #056441 !important;
  font-weight: 600 !important;
}

.details-text {
  margin: 0px !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em !important;
  text-transform: none !important;
  vertical-align: unset !important;
  text-decoration: none !important;
  color: rgb(123, 128, 154) !important;
  font-weight: 400 !important;
}

.btn-icon {
  color: rgb(123, 128, 154) !important;
}

.css-1k51tf5-MuiTooltip-tooltip {
  /* color: black !important; */
  background-color: black !important;
  padding: 10px;
  border-radius: 5px;
}

.css-kudwh-MuiTooltip-arrow {
  color: black !important;
}

/* th .profile-title { 
  color: rgb(255, 255, 255)  !important;
  font-size: 1rem  !important;
} */

.profile-title {
  margin: 0px !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.02857em !important;
  opacity: 1 !important;
  text-transform: capitalize !important;
  vertical-align: unset !important;
  text-decoration: none !important;
  color: rgb(52, 71, 103) !important;
  font-weight: 700 !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
}

.avatar_section {
  margin: auto !important;
}

.avatar-profile {
  margin: auto !important;
}

.avatar-profile .profile_upload {
  opacity: 0;
  position: absolute;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.avatar-profile .profile_upload label {
  width: 161px;
  height: 151px;
  background: #ffffff00;
}
.avatar-profile:hover .profile_upload {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.274);
}

.list_profile .profile_upload {
  opacity: 0 !important;
  margin-left: 3%;
}

.note {
  color: #637381 !important;
  margin-top: -20px !important;
  font-size: 12px !important;
}

.avatar_img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url(../src/assets/images/upload.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px dashed rgba(145, 158, 171, 0.32);
}
.company_avatar_img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url(../src/assets/images/coLogo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px dashed rgba(145, 158, 171, 0.32);
}

.MuiButton-endIcon {
  display: none !important;
}

.advance_paymenttable {
  border: 1px solid #00000054;
  padding: 30px !important;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .advance_paymenttable {
    margin-left: 5% !important;
  }
  .menu-bar {
    align-content: flex-end !important;
    flex-direction: column !important;
    padding-right: 20px;
    flex-wrap: wrap !important;
  }
  .installment-tab {
    padding: 0px 20px !important;
  }
}

@media (max-width: 768px) {
  .advance_pay {
    padding: 0px !important;
  }
}

.hr_tag {
  margin-left: 0px !important;
}

.note_text {
  text-align: right;
  font-size: 12px;
  margin-top: -15px;
}
.note_text b {
  color: red;
}

/* Table
.table_column .MuiDataGrid-columnHeader {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important;
}

.table_column .MuiDataGrid-cell {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important;
} */

/* .form-control {
  margin-bottom: 10px !important;
} */

.menu_icon {
  margin: auto;
  margin-right: inherit;
}

.header_alignment {
  align-items: stretch !important;
  flex-direction: column;
}

.form-lable h3 {
  margin: 30px 0px 0px !important;
}

.text-right {
  text-align: right;
}

.element-for-print,
.element-to-print {
  width: 80%;
  margin: auto;
}

.element-to-po {
  width: 70%;
  margin: auto;
}

p.td {
  font-size: 13px;
}

.no-border {
  border: 0px !important;
  margin-left: 20px;
}
.css-1f8bwsm {
  min-width: 23px !important;
  margin-left: 0px;
}
.gcHybU {
  max-height: 100% !important;
  margin-left: 0px;
}

.top_sticky {
  position: fixed;
  background-color: #f4f4f4 !important;
  width: 100%;
  top: 100px;
  z-index: 99999;
}


.button_elect .MuiSelect-select{
  padding: 10px 40px !important;
    background: #ffffff;
    color: var(--primary-color);
    border: 2px solid var(--side-menu-hover-color);
    font-weight: 700;
}

.button_elect{
  margin-right: 20px;
}







/* dashboard */
.dashboard-card-3 {
  border-radius: 25px 5px 25px 5px;
  display: grid;
  height: 120px !important;
  padding: 8px;
  text-align: center;
}

.d-green {
  background-color: rgba(128, 255, 219, 0.5);
  /* color: rgb(62, 152, 125); */
  color: #07beb8;
}
.d-pink {
  background-color: rgba(171, 135, 255, 0.4);
  color: #ab87ff;
}

.d-red {
  background-color: #ffccd5;
  color: #e01e37;
}

.d-green-1 {
  background-color: #b7efc5;
  color: #008000;
}
.d-red-1 {
  background-color: rgba(255, 239, 159, 0.5);
  color: #fbb02d;
}
.dashboard-card {
  cursor: pointer;
}

.top-selling {
  color: rgb(17, 25, 39) !important;
  margin: 0px !important;
  font-weight: 700 !important;
  font-size: 1.0625rem !important;
  line-height: 1.2 !important;
  display: block !important;
}

.no-border {
  border: 0px !important;
  margin-left: 20px;
}

.no-border:hover {
  background-color: rgba(17, 25, 39, 0.04);
}

.no-border td {
  border: 0px;
}

.dashboard-td {
  padding: 7px 16px;
}

.td_t {
  margin: 0px !important;
  font-size: 0.85rem !important;
  font-weight: 700 !important;
  line-height: 1.57 !important;
  color: rgb(17, 25, 39) !important;
}

.dashboard-card_2 {
  display: flex !important;
  flex-direction: row !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  /* background-color: rgb(254, 243, 242) !important; */
  border-radius: 20px !important;
  padding: 32px 24px !important;
}

.pink {
  background-color: rgb(255 234 240) !important;
}

.orange {
  background-color: rgb(254, 243, 242) !important;
}
.light_green {
  background-color: rgb(233, 199, 249) !important;
}

.dashborad-card-title {
  margin: 0px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.57 !important;
  color: rgb(108, 115, 127) !important;
}


.dashborad-card-data {
  font-size: 1.4583rem !important;
  margin: 0px !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  color: rgb(17, 25, 39) !important;
}

/* Table Header */
.sc-eDWCr {
  font-weight: 700;
  font-size: 13px;
}

.table_header {
  background-color: beige;
}